@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .h-688 {
    height: 688px;
    position: relative;
    /* overflow: hidden; */
  }
}

@font-face {
  font-family: 'Toyota-Type Book';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/ToyotaType-Book.otf') format('opentype');
}
@font-face {
  font-family: 'Toyota-Type Book';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/ToyotaType-BookIt.otf') format('opentype');
}

@font-face {
  font-family: 'Toyota-Type Semibold';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/ToyotaType-Semibold.otf') format('opentype');
}
@font-face {
  font-family: 'Toyota-Type Semibold';
  font-style: italic;
  font-weight: 600;
  src: url('./assets/fonts/ToyotaType-SemiboldIt.otf') format('opentype');
}

@font-face {
  font-family: 'Toyota-Type Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/ToyotaType-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Toyota-Type Regular';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/ToyotaType-RegularIt.otf') format('opentype');
}

body {
  margin: 0;
  font-family: 'Toyota-Type Book';
  font-style: normal;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* width */
::-webkit-scrollbar {
  height: 2px;
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--white);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-red);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
