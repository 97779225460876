.spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lds-dual-ring {
  display: inline-block;
  width: 150px;
  height: 150px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 150px;
  height: 150px;
  margin: 4px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
