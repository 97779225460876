.main-container {
  width: 100%;
  height: 100%;
  padding: 48px;

  .main-content {
    margin-top: 64px;
    margin-bottom: 112px;
    height: calc(100% - 128px - 160px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__default-img {
      margin-bottom: 60px;
      max-height: 40px;
      display: flex;
      align-items: center;
    }

    &__title {
      font-family: 'Toyota-Type Semibold';
      font-style: normal;
      font-size: 34px;
      text-transform: uppercase;
      color: var(--primary-black);
      margin-bottom: 40px;
      text-align: center;
    }

    &__car {
      // width: 50%;
      height: 600px;
      padding: 100px 63px 80px 63px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
    }
    .default {
      background-color: #fff;
      max-width: 437px;
      max-height: 288px;
      overflow: hidden;
      // margin-right: 24px;
      // margin-bottom: 24px;
      padding: 40px;
      &-img {
        svg {
          color: var(--primary-red);
          fill: var(--primary-red);
        }
        margin-bottom: 60px;
      }
      &-title {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 16px;
      }
      &-description {
        font-size: 16px;
        line-height: 24px;
        color: var(--gray-7);
      }
    }

    &__cars {
      display: flex;
      max-width: 1824px;
      max-height: 704px;
      width: 100%;
      overflow: hidden;
      :hover {
        opacity: 0.85;
        transition: ease-in-out 0.4s;
      }
      &-img {
        margin-bottom: 64px;
        display: flex;
        justify-content: center;
        max-height: 212px;
        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        &.default-content {
          align-items: flex-start;
          padding: 0;
        }
      }

      &-title {
        font-family: 'Toyota-Type Regular';
        font-size: 28px;
        color: var(--white);
        margin-bottom: 16px;
        &.default {
          display: flex;
          justify-content: flex-start;
          color: var(--primary-black);
          padding: 0;
          font-size: 22px;
          line-height: 28px;
          margin-bottom: 16px;
        }
      }

      &-description {
        font-size: 16px;
        color: var(--white);
        margin-bottom: 56px;
        text-align: center;
        &.default {
          font-size: 16px;
          line-height: 24px;
          color: var(--gray-7);
          padding: 0;
          margin-bottom: 0;
          text-align: start;
        }
      }

      &-button {
        display: flex;
        justify-content: center;
        .main-content__cars-description {
          margin-bottom: 0;
        }
        p {
          border-bottom: 1px var(--white) solid;
          width: fit-content;
          padding-bottom: 20px;
        }
      }

      &-new {
        background: var(--primary-black);
      }
    }
    &__banner {
      background: var(--primary-red);
    }
    &__default {
      background: var(--primary-red);
    }
    &__cars-wrapper {
      width: max-content;
      overflow-x: auto;
      overflow-y: auto;
      display: flex;
      width: 4000px;
      font-size: 0;
      justify-content: center;
    }
  }
  .footer {
    .pagination {
      margin-left: 70px;
    }
  }
}
.form {
  width: 500px;
  margin: 0 auto 0;
  &__line {
    margin-bottom: 33px;
    &.borderType_1 {
      border-top: 1px solid var(--gray-4);
      border-bottom: 1px solid var(--gray-4);
      margin-top: 30px;
      margin-bottom: 24px;
      padding-top: 15px;
      padding-top: 15px;
      padding-bottom: 30px;
    }
    &.buttonWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        span {
          font-size: 16px;
        }
      }
    }
  }
  .submitButton {
    font-size: 16px;
    width: 200px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    background-color: var(--primary-black);
  }
}
.car-list {
  display: grid;
  gap: 24px;
  &__item {
    list-style: none;
    width: 437px;
    margin-right: 24px;
    height: 288px;
    .default {
      height: 100%;
    }
    &.banner {
      height: 600px;
    }
    &.w50 {
      width: 592px;
    }
  }
}
.card-list {
  &__item {
    width: 360px;
  }
}
.cards {
  height: 600px;
  display: grid;
  row-gap: 24px;
  .banner {
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .default {
  }
  &.row-1 {
    grid-template-columns: 1fr;
    width: 100%;
    .car-list__item {
      width: 100%;
    }
  }
  &.row-2 {
    grid-template-columns: 1fr 1fr;
    width: 100%;
    .car-list__item {
      width: 900px;
      .default {
        max-width: 900px;
      }
    }
  }
  &.row-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  &.row-3 {
    grid-template-columns: 1fr 1fr 1fr;
    .car-list__item {
      width: 590px;
      .default {
        max-width: 590px;
      }
    }
  }
}
