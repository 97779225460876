.breadcrumbs {
  display: flex;

  .breadcrumb {
    font-family: 'Toyota-Type Regular';
    font-size: 16px;
    color: var(--gray-6);

    &_active {
      color: var(--primary-black);
    }
  }
}
