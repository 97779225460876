:root {
  --white: #ffffff;
  --black: #000000;
  --gray-1: #f5f5f5;
  --gray-4: #e4e4e4;
  --gray-5: #d9d9d9;
  --gray-6: #a8aaac;
  --gray-7: #6c7073;
  --gray-8: #C7CFD7;
  --gray-9: #E2E8EF;
  --primary-black: #282830;
  --highlight-black: #15151b;
  --secondary-black: #101010;
  --primary-red: #ff0022;
  --effective-blue: #0072f0;
}
