.brochure-pdf-modal {
  width: 1200px;
  height: 520px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  font-size: 28px;
  color: var(--primary-black);
  background-color: var(--white);
  padding: 48px;
  p {
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
  }
  .title {
    font-family: 'Toyota-Type Regular';
    text-align: center;
    font-size: 28px;
  }
  .subText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-black);
  }
  .wrapperQrCode {
    width: 140px;
    height: 140px;
    border: 1px solid var(--gray-4);
    padding: 10px;
    margin-top: 40px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .description {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: var(--gray-7);
    max-width: 299px;
    margin-top: 16px;
  }
  &__content {
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    button {
      width: fit-content !important;
      padding: 0 20px;
      margin-top: 48px;
    }
  }
}
