.menu {
  width: 496px;
  height: 1080px;
  background-color: var(--white);
  position: relative;
  -webkit-box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);

  .close-button {
    width: 48px;
    height: 80px;
    background-color: var(--primary-black);
    position: absolute;
    right: -48px;
    top: 53.5%;
    display: flex;
    justify-content: center;
    align-items: center;

    &_opened::after {
      content: '';
      display: block;
      width: 25px;
      height: 25px;
      border-left: 2px solid var(--white);
      border-bottom: 2px solid var(--white);
      transform: rotate(45deg) translate(20%, -20%);
    }
    &_opened:active {
      opacity: 0.8;
    }

    &_closed::after {
      content: '';
      display: block;
      width: 25px;
      height: 25px;
      border-right: 2px solid var(--white);
      border-bottom: 2px solid var(--white);
      transform: rotate(-45deg) translate(-20%, -10%);
    }
    &_closed:active {
      opacity: 0.8;
    }
  }

  &-logo {
    height: 154px;
    display: flex;
    align-items: center;
    padding: 35px 0 35px 48px;
    // border-bottom: 2px solid var(--gray-4);

    p {
      font-weight: 400;
      font-size: 22px;
      text-transform: uppercase;
      color: var(--gray-7);
      margin-left: 32px;
    }
  }

  &-parts {
    width: 496px;
    height: 828px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0;

    &__part {
      height: 104px;
      padding: 31px 0 31px 48px;
      // border-top: 1px solid var(--gray-4);
      border-bottom: 1px solid var(--gray-4);
      display: flex;
      align-items: center;
      cursor: pointer;

      p {
        font-family: 'Toyota-Type Regular';
        font-size: 20px;
        color: var(--primary-black);
      }
      &:hover,
      &:active,
      &:focus {
        outline: none;
      }
    }
    &__part-enabled {
      height: 104px;
      // height: 100px;
      background: var(--gray-1);
      border-bottom: 4px solid var(--primary-red);
      padding: 31px 0 31px 48px;
      display: flex;
      align-items: center;

      p {
        font-family: 'Toyota-Type Semibold';
        font-size: 20px;
        color: var(--primary-black);
      }
    }

    .icon {
      width: 40px;
      height: 40px;
      //display: flex;
      justify-content: flex-end;
      margin-right: 16px;
    }
  }

  &-closed {
    width: 182px;

    .menu-logo {
      height: 154px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }

    .menu-parts {
      width: 182px;

      &__part {
        height: 104px;
        border-bottom: 1px solid var(--gray-4);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
      }

      &__part-enabled {
        height: 100px;
        background: var(--gray-1);
        border-bottom: 4px solid var(--primary-red);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
      }

      .icon {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  &-swiper {
    margin-left: 0!important;
    margin-right: 0!important;
    // max-height: 728px;
  }
  .prev,
  .next {
    display: flex;
    width: 100%;
    height: 48px;
    background-color: #F5F5F5;
    align-items: center;
    justify-content: center;
    &.disabled,
    &.swiper-button-disabled {
      pointer-events: none;
      svg {
        opacity: 0.5;
      }
    }
  }
  .prev {
    transform: rotate(180deg);
  }
  .swiper-pagination-bullets {
    height: 100%;
    right: 0!important;
    .custom-bullet {
      width: 2px;
      border-radius: none;
      margin: 0!important;
      background-color: transparent!important;
      transition: background-color .6s;
    }
    .swiper-pagination-bullet-active {
      background-color: var(--primary-red)!important;
      transition: background-color .6s;
    }
  }
}
.scrollMenu {
  &__content {
    max-height: 728px;
    height: 100%;
    overflow-y: auto;

    /* width */
    &::-webkit-scrollbar {
      width: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--white);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--primary-red);
    }
  }
}
.menu-swiper-container {
  position: relative;
  max-height: 728px;
  overflow: hidden;
   .swiper-slide {
     //height: 104px!important;
   }
  .swiper {
    max-height: 728px;
  }
  .swiper-scrollbar.swiper-scrollbar-vertical,.swiper-vertical>.swiper-scrollbar {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 50;
    width: 2px;
    height: 100%
  }
  .swiper-scrollbar-drag {
    width: 100%;
    position: relative;
    background: var(--primary-red);
    border-radius: 0;
    left: 0;
    top: 0
  }
}
.swiper-scrollbar {
  background: transparent!important;
  --swiper-scrollbar-bottom: 0;
  --swiper-scrollbar-drag-bg-color: #dda3b6;
  --swiper-scrollbar-size: 5px;
}
