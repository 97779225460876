.select {
  // border: 1px solid var(--gray-4);
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  position: relative;
  background-color: rgb(255, 255, 255);
  &.error {
    border-color: var(--primary-red);
  }
  &__placeholder {
    color: var(--gray-7);
    width: 100%;
    display: flex;
    position: absolute;
    left: 20px;
    z-index: 0;
    font-size: 16px;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &__clearBtn {
    display: block;
    position: absolute;
    right: 50px;
    width: 20px;
    height: 20px;
    z-index: 5;
  }
  &__curretIcon {
    position: absolute;
    right: 20px;
    transition: all .3s ease-in-out;
    &.open {
      transition: all .3s ease-in-out;
      transform: rotate(180deg);
    }
  }
  &__selectedValue {
    width: calc(100% - 40px);
    overflow: hidden;
    position: relative;
    z-index: 2;
    padding: 15px 20px;
    font-size: 16px;
    color: var(--gray-7);
  }
  &__optList {
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background-color: #fff;
    list-style-type: none;
    padding: 0;
    max-height: 300px;
    overflow-y: auto;
    &.top {
      top: auto;
      bottom: 100%;
    }

    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    margin-bottom: 8px;
    margin-top: 8px;
    ::-webkit-scrollbar {
      width: 1px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      background: var(--white);
      margin-top: 48px;
      margin-bottom: 48px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--primary-red);
    }

    &-item {
      padding: 10px 20px;
      color: var(--gray-7);
      font-size: 16px;
      cursor: pointer;
      &:hover,
      &.selected {
        background-color: var(--gray-1);
        color: var(--primary-black);
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--gray-4);
      }
      &-noItems {
        color: var(--gray-7);
        padding: 10px 20px;
        text-align: center;
      }
    }
  }
  .errorText {
    position: absolute;
    bottom: -18px;
    font-size: 13px;
    z-index: 0;
    left: 0;
    color: var(--primary-red);
  }
}
.required {
  color: var(--primary-red);
  margin-left: 7px;
}
