.screen-title {
  display: flex;
  margin-bottom: 24px;
  align-items: center;

  .title-container {
    line-height: 1;
    vertical-align: middle;
    max-width: calc(100% - 460px);
    &__margin {
      margin: 0 24px;
    }
  }

  &__text {
    font-family: 'Toyota-Type Semibold';
    font-size: 34px;
    text-transform: uppercase;
    // margin-bottom: 5px;
  }

  .gray-text {
    color: var(--gray-6);
  }

  .black-text {
    color: var(--primary-black);
    display: inline-flex;
    svg {
      margin-top: 5px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__right {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}
