.tech-map-list {
  display: flex;
  width: 100%;

  .list-of-sections {
    width: 329px;
    padding-top: 58px;
    padding-left: 58px;
    padding-right: 58px;
    height: 100%;
    border-right: 2px solid var(--gray-4);
    overflow-y: scroll;
    overflow-x: hidden;

    &::after {
      display: block;
      content: '';
      height: 58px;
      width: 100%;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--white);
      margin-top: 58px;
      margin-bottom: 58px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--primary-red);
    }

    .section-list {
      width: 230px;

      &__item {
        display: block;
        width: fit-content;
        font-size: 16px;
        color: var(--primary-black);
        padding-bottom: 12px;
        margin-bottom: 32px;
        border-bottom: 1px solid transparent;
      }
      &__item_active {
        border-bottom: 1px solid var(--primary-red);
        font-family: 'Toyota-Type Regular';
      }

      &__item:last-child {
        margin-bottom: 0;
      }
    }
  }
  .characteristics {
    flex-grow: 1;

    overflow-x: hidden;
    overflow-y: auto;
    font-family: 'Toyota-Type Regular';

    /* width */
    &::-webkit-scrollbar {
      width: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--white);
      margin-top: 58px;
      margin-bottom: 58px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--primary-red);
    }

    .characteristics-list {
      width: 100%;
      padding-top: 58px;
      height: 100%;
      padding-left: 110px;
      &::after {
        display: block;
        content: '';
        height: 58px;
        width: 100%;
      }

      &__item {
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .select__indicator {
    svg {
      fill: var(--primary-red);
    }
  }
}
.sectionData {
  font-family: 'Toyota-Type Regular';
  max-width: 749px;
  &__content {
    max-height: 385px;
    overflow-y: scroll;
    overflow-x: hidden;
    /* width */
    &::-webkit-scrollbar {
      width: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--white);
      margin-top: 58px;
      margin-bottom: 58px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--primary-red);
    }
  }
  &__content-body {
    margin-bottom: 10px;
    padding-right: 8px;
  }
  &__content-footer {
    font-size: 13px;
    color: var(--gray-6);
    font-weight: 400;
    font-family: 'Toyota-Type Regular';
  }
  &__item {
    padding: 13px 0;
    font-size: 13px;
    color: var(--gray-7);
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-top: 1px solid #e4e4e4;
      padding-top: 26px;
      margin-top: 13px;
    }
  }
  &__col {
    align-items: center;
    &.value {
      color: var(--primary-black);
      font-size: 13px;
    }

    &-list {
      margin-left: 10px;
      width: 170px;
    }

    &-last {
      margin-bottom: 30px;
    }
  }
  &.required {
  }
  &.diagnostic {
    .sectionData__item {
      .label {
        max-width: 50%;
      }
      .value {
        text-align: right;
      }
      &:first-child {
        border-bottom: 1px solid #e4e4e4;
        border-top: none;
        .label,
        .value {
          font-size: 16px;
          color: var(--primary-black);
        }
        .value {
          text-align: right;
        }
      }
      &:last-child {
        border-bottom: none;
        border-top: none;
        margin-top: 0;
        padding-top: 13px;
      }
    }
  }
  &.regulatory,
  &.materials {
    .sectionData__item {
      .label {
        width: 50%;
        // max-width: 33%;
      }
      .value {
        text-align: center;
        width: 25%;
        color: var(--gray-7);
        font-size: 13px;
      }
      .price {
        text-align: right;
        width: 25%;
        color: var(--primary-black);
        font-size: 16px;
      }
      &:first-child {
        border-bottom: 1px solid #e4e4e4;
        border-top: none;
        .label,
        .value,
        .price {
          font-size: 16px;
          color: var(--primary-black);
        }
        .value {
          text-align: center;
        }
      }
      &:last-child {
        border-bottom: none;
        border-top: none;
        margin-top: 0;
        padding-top: 13px;
      }
    }
  }
}
