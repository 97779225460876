.modal {
  width: 1200px;
  //height: 676px;
  position: fixed;
  top: 202px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  font-size: 28px;
  color: var(--primary-black);
  background-color: var(--white);
  padding: 72px;
  &__title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 46px;
  }
  &__content {
    max-width: 555px;
    width: 100%;
  }
}

.modal {
  text-align: center;
  //height: 560px;
  &__title {
    font-size: 16px;
  }
  &__timer {
    margin-bottom: 40px;
  }
  &__timer-descr {
    font-size: 28px;
    margin-bottom: 43px;
  }
  &__buttonWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      font-size: 16px;
      &:last-child {
        margin-left: 24px;
      }
      span {
        font-size: 16px;
      }
    }
  }
}
.btn {
  font-size: 16px;
  width: 200px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  background-color: var(--primary-black);
  &.transparent {
    color: var(--highlight-black);
    background: var(--white);
    border: 1px solid var(--highlight-black);
  }
}
