.tipsModal{
  &.modal {
    width: 1524px;
    height: 616px;
    position: fixed;
    top: 140px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    font-size: 28px;
    color: var(--primary-black);
    background-color: var(--white);
    padding: 0;
    video{
      button{
        display: none!important;
      }
    }
    .content {
      width: 100%;
      height: 100%;
      .carousel{
        width: 100%;
        height: 100%;
      }
      .slider-button-wrapper{
        top: 512px!important;
        &.whiteColor .chevron__right{
          border-bottom: 1px solid var(--primary-black);
          border-right: 1px solid var(--primary-black);
          transform: rotate(-45deg) translate(-10%, -15%);
        }
        &.whiteColor .chevron__left{
          border-bottom: 1px solid var(--primary-black);
          border-left: 1px solid var(--primary-black);
          transform: rotate(45deg) translate(15%, -20%);
        }
        &:active{
          .chevron__right{
            border-bottom: 1px solid var(--white);
            border-right: 1px solid var(--white);
            transform: rotate(-45deg) translate(-10%, -15%);
          }
          .chevron__left{
            border-bottom: 1px solid var(--white);
            border-left: 1px solid var(--white);
            transform: rotate(45deg) translate(15%, -20%);
          }
        }
        &.withColor{
          border: 1px solid var(--primary-black);
        }
      }
    }
    &.hidePagination{
      .slider-button-wrapper, .paginationSlider{
        display: none;
      }
    }
    .wrapperSlideView{
      display: flex;
      .leftBlock{
        width: 996px;
        height: 616px;
        .videoWrapper{
          position: relative;
          background-color:var(--black);
          .shadowBg{
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: var(--primary-black);
            opacity: 0.3;
          }
          .toggleVideoBtn{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 99;
            &.hidde{
              display: none;
            }
          }
          .absoluteTitle{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom:67px;
            z-index: 999;
            font-weight: 400;
            font-size: 22px;
            line-height: 28px;
            text-align: center;
            color: var(--white);
            font-family: 'Toyota-Type Regular';
            max-width: 504px;
          }
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .rightBlock{
        position: relative;
        width: 530px;
        text-align: left;
        font-family: 'Toyota-Type Regular';
        .paginationSlider{
          position: absolute;
          bottom: 60px;
          left: 155px;
          span{
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color:var(--gray-6);

            &.active{
              color:var(--primary-black)
            }
            &.withMr{
              display: inline-block;
              padding: 0 3px;
            }
          }
        }
        h1 {
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 48px;
        }
        h2 {
          font-family: "Toyota-Type Semibold";
          font-size: 20px;
          line-height: 24px;
          margin-top: 40px;
          margin-bottom: 16px;
          font-weight: 600;
        }
        h3 {
          font-size: 12px;
          line-height: 19px;
          margin-bottom: 16px;
        }
        p {
          font-family: 'Toyota-Type Book';
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 16px;
        }
        strong{
          font-size: 20px;
          line-height: 24px;
          font-weight: 600;
        }
        ul {
          font-family: "Toyota-Type Book";
          padding-left: 24px;
          li {
            font-size: 16px;
            line-height: 24px;
            position: relative;
            margin-top: 24px;
            &::before {
              content: '';
              display: block;
              position: absolute;
              left: -24px;
              top: 11px;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: var(--primary-red);
            }
          }
        }
        ol {
          font-family: "Toyota-Type Book";
          margin-bottom: 48px;
          counter-reset: item;
          li {
            font-size: 16px;
            line-height: 24px;
            position: relative;
            margin-bottom: 8px;
            list-style-type: 1;
            counter-increment: step-counter;
            &::before {
              content: counter(step-counter) '. ';
              color: var(--primary-red);
              font-weight: 700;
            }
          }
        }
        .description {
          color: var(--primary-black);
          max-height: 380px;
          overflow-y: auto;
          overflow-x: hidden;
          padding: 0 48px;
          /* width */
          &::-webkit-scrollbar {
            width: 2px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: var(--white);
            margin-top: 48px;
            margin-bottom: 48px;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: var(--primary-red);
          }
        }
        .title{
          max-width: 400px;
          font-family: 'Toyota-Type Regular';
          text-align: left;
          font-weight: 400;
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 40px;
          padding-left: 48px;
          padding-top: 48px;
        }
      }
    }
  }
}


