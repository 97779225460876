.filter-modal {
  width: 1200px;
  padding: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  outline: none !important;

  &__close {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 24px;
    top: 24px;
    background-color: var(--primary-black);
    color: white;
    font-size: 12px;
    border-radius: 50%;
    opacity: 0.9;

    &:active {
      opacity: 0.8;
    }
  }

  &__title {
    font-family: 'Toyota-Type Regular';
    font-size: 28px;
    color: var(--primary-black);
  }

  .buttons {
    margin-top: 64px;
    display: flex;
    justify-content: flex-end;
  }
}
