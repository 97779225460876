.car-characteristics-list {
  display: flex;
  width: 100%;

  .list-of-sections {
    width: 329px;
    padding-top: 48px;
    padding-left: 48px;
    padding-right: 48px;
    height: 100%;
    border-right: 1px solid var(--gray-4);
    overflow-y: scroll;
    overflow-x: hidden;

    &::after {
      display: block;
      content: '';
      height: 48px;
      width: 100%;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 1px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--white);
      margin-top: 48px;
      margin-bottom: 48px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--primary-red);
    }

    .section-list {
      width: 250px;

      &__item {
        display: block;
        width: fit-content;
        font-size: 16px;
        color: var(--primary-black);
        padding-bottom: 12px;
        margin-bottom: 32px;
        border-bottom: 1px solid transparent;
      }
      &__item_active {
        border-bottom: 1px solid var(--primary-red);
        font-family: 'Toyota-Type Regular';
      }

      &__item:last-child {
        margin-bottom: 0;
      }
    }
  }
  .characteristics {
    flex-grow: 1;
    padding-left: 110px;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: 'Toyota-Type Regular';

    /* width */
    &::-webkit-scrollbar {
      width: 1px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--white);
      margin-top: 24px;
      margin-bottom: 24px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--primary-red);
    }

    .characteristics-list {
      width: 100%;
      padding-top: 24px;
      height: 100%;
      &::after {
        display: block;
        content: '';
        height: 24px;
        width: 100%;
      }

      &__item {
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
