button.back-button {
  width: 48px;
  height: 48px;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary-black);
  border-radius: 24px;
}

button.back-button:active {
  background-color: var(--primary-black);
}
