.successful {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  &__text {
    text-align: center;
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 96px;
  }
  &__actionBtn {
    .btn {
      color: var(--white);
      background-color: var(--primary-black);
      padding: 12px 24px;
      width: 317px;
      height: 48px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
.modal {
  width: 1200px;
  min-height: 676px;
  position: fixed;
  top: 202px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  font-size: 28px;
  color: var(--primary-black);
  background-color: var(--white);
  padding: 72px;
  &__title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 46px;
  }
  &__content {
    max-width: 555px;
    width: 100%;
  }
}

.form {
  &__line {
    margin-bottom: 17px;
    &.borderType_1 {
      border-top: 1px solid var(--gray-4);
      border-bottom: 1px solid var(--gray-4);
      margin-top: 30px;
      margin-bottom: 24px;
      padding-top: 15px;
      padding-top: 25px;
      padding-bottom: 30px;
    }
    &.buttonWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        span {
          font-size: 16px;
        }
      }
    }
  }
  .submitButton {
    font-size: 16px;
    width: 200px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    background-color: var(--primary-black);
  }
  &__additionalText {
    font-size: 13px;
    color: var(--primary-black);
    margin-bottom: 24px;
  }
}
.privacyContent,
.testDriveContent {
  padding: 80px 40px 40px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  // width: 80%;
  // height: 80%;
  position: absolute;
  // left: calc(50% - 40%);
  // top: calc(50% - 40%);
  background-color: var(--white);
  overflow-y: auto;
  h1 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 48px;
  }
  h2 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  h3 {
    font-size: 12px;
    line-height: 19px;
    margin-bottom: 16px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 48px;
  }
  ul {
    padding-left: 44px;
    margin-bottom: 48px;
    li {
      font-size: 16px;
      line-height: 24px;
      position: relative;
      margin-bottom: 8px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: -24px;
        top: 11px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--primary-red);
      }
    }
  }
  ol {
    margin-bottom: 48px;
    counter-reset: item;
    li {
      font-size: 16px;
      line-height: 24px;
      position: relative;
      margin-bottom: 8px;
      list-style-type: 1;
      counter-increment: step-counter;
      &::before {
        content: counter(step-counter) '. ';
        color: var(--primary-red);
        font-weight: 700;
      }
    }
  }

  /* width */
  &::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--white);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--primary-red);
  }

  /* width */
  &::-moz-scrollbar {
    width: 2px;
  }

  /* Track */
  &::-moz-scrollbar-track {
    background: var(--white);
  }

  /* Handle */
  &::-moz-scrollbar-thumb {
    background: var(--primary-red);
  }
  &__text {
  }
}
