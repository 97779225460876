.panoramaViewWrapper{
  position: relative;
  .iconWrapper{
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
