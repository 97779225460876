
.modal {
  width: 1200px;
  //height: 676px;
  position: fixed;
  top: 202px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  font-size: 28px;
  color: var(--primary-black);
  background-color: var(--white);
  padding: 72px;
  p{
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
  }
  .wrapperQrCode{
    border: 1px solid var(--gray-4);
    padding: 15px;
    margin-bottom: 16px;
    margin-top: 50px;
    svg{
      width: 120px;
      height: 120px;
    }
  }
  .description{
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--gray-7);
    max-width: 299px;
    text-align: center;
  }
  &__title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 46px;
  }
  &__content {
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    button{
      width: fit-content!important;
      padding: 0 20px;
      margin-top:48px;
    }
  }
}

