.primary-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-black);
  color: var(--white);
  font-family: 'Toyota-Type Regular';
  // min-width: 220px;
  padding: 0 20px;
  .button-icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .text {
    font-size: 20px;
    line-height: 24px;
  }

  .badge {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    span {
      font-size: 16px;
      color: var(--white);
      margin-right: 2px;
    }
  }

  .book {
    font-family: 'Toyota-Type Book';
  }
  .semibold {
    font-family: 'Toyota-Type Semibold';
  }
}

.primary-button:disabled {
  background-color: var(--gray-4);
}

.secondary-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  color: var(--primary-black);
  border: 1px solid var(--primary-black);
  font-family: 'Toyota-Type Regular';
  // min-width: 220px;
   padding: 0 20px;
  .button-icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.secondary-button:disabled {
  border-color: var(--gray-6);
  color: var(--gray-6);
}

.rounded-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray-6);
  background-color: var(--white);
  color: var(--gray-7);
  // min-width: 220px;
   padding: 0 20px;
  span {
    font-family: 'Toyota-Type Regular';
    font-size: 20px;
  }

  span.small {
    font-size: 16px;
    margin: 0 8px;
    line-height: 143%;
  }

  .badge {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;

    span {
      font-size: 20px;
      color: var(--white);
    }
    &-small {
      width: 24px;
      height: 24px;
      span {
        font-size: 16px;
      }
    }
  }
}
