.slider-button {
  background-color: var(--white);
  width: 64px;
  height: 64px;
  border-radius: 32px;
  border: 1px solid var(--primary-black);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-button:active:not(:disabled) {
  background-color: var(--primary-black);
}

.slider-button:disabled {
  border-color: var(--gray-4);
}

.button-left::after {
  content: '';
  display: block;
  box-sizing: border-box;
  transform: rotate(45deg) translate(15%, -20%);
  width: 15px;
  height: 15px;
  border-bottom: 1px solid var(--black);
  border-left: 1px solid var(--black);
}

.button-left:active::after {
  transform: rotate(45deg) translate(15%, -20%);
  border-bottom: 1px solid var(--white);
  border-left: 1px solid var(--white);
}

.button-left:disabled::after {
  transform: rotate(45deg) translate(15%, -20%);
  border-bottom: 1px solid var(--gray-6);
  border-left: 1px solid var(--gray-6);
}

.button-right::after {
  content: '';
  display: block;
  box-sizing: border-box;
  transform: rotate(-135deg) translate(10%, -5%);
  width: 15px;
  height: 15px;
  border-bottom: 1px solid var(--black);
  border-left: 1px solid var(--black);
}
.button-right:active::after {
  transform: rotate(-135deg) translate(10%, -5%);
  border-bottom: 1px solid var(--white);
  border-left: 1px solid var(--white);
}

.button-right:disabled::after {
  transform: rotate(-135deg) translate(10%, -5%);
  border-bottom: 1px solid var(--gray-6);
  border-left: 1px solid var(--gray-6);
}
