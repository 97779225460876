#root {
  max-height: 1080px;
  max-width: 1920px;
  height: 100%;
  width: 100%;
}

html,
body,
.App {
  height: 100%;
  background-color: #f5f5f5;
  overflow: hidden;
}

.App {
  display: flex;
}
.App-content {
  text-align: center;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 48px;
}
.swiper-pagination {
  display: none!important;
}

.modalHidden {
  opacity: 0 !important;
}
