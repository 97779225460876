.wrapperTab{
  display: flex;
  margin-bottom: 34px;
  margin-left: 40px;
  .tabItem{
    font-family: "Toyota-Type Book";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-black);
    margin-right: 24px;
    border-bottom: 1px solid transparent;
    margin-bottom: -1px;
    padding-bottom: 12px;
    &.active{
      font-family: "Toyota-Type Regular";
      border-color: var(--primary-red);
    }
    &:last-child{
      margin-right: 0;
    }

  }
}
