.dropdown {
  position: relative;
  &__button {
    height: 48px;
    width: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-black);
    opacity: 0.5;

    &:active {
      opacity: 1;
    }
  }

  &__list {
    position: absolute;
    right: 0px;
    width: 300px;
    background-color: var(--white);
    color: var(--gray-7);
    font-size: 16px;
    border: 1px solid var(--gray-4);
    margin-top: 10px;
    z-index: 4;

    ul {
      li {
        padding: 16px 20px;
        border-bottom: 2px solid var(--gray-4);

        &:last-child {
          border-bottom: none;
        }
      }

      li.active {
        background-color: var(--gray-1);
      }
    }
  }
}
