.countdownTimer {
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 50%;
    border-width: 3px;
    border-style: solid;
    &.start {
        border-color: red;
    }
    &.active {
        border-color: #ccc;
    }
    &.timeOut {
        border-color: #ccc;
    }

    position: relative;
    margin: auto;
    text-align: center;
    &__roundBar {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &:after {
            content: '';
            width: 100%;
        }
    }
    &__time {
        font-weight: 600;
        font-size: 34px;
        line-height: 40px;
    }
    svg {
        position: absolute;
        top: -3px;
        right: -3px;
        width: 140px;
        height: 140px;
        transform: rotateY(-180deg) rotateZ(-90deg);
    }
      
    svg circle {
        stroke-dasharray: 433px;
        stroke-dashoffset: 0px;
        stroke-linecap: round;
        stroke-width: 2px;
        stroke: red;
        fill: none;
        animation: countdown linear forwards;
        // animation: countdown linear infinite forwards;
    }
      
    @keyframes countdown {
        from {
            stroke-dashoffset: 0px;
        }
        to {
            stroke-dashoffset: 433px;
        }
    }
}