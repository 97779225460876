.header {
  display: flex;
  align-items: center;

  &-logo {
    display: flex;
    align-self: center;
    margin-right: 32px;
  }

  &-title {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: var(--gray-7);
    text-transform: uppercase;
    margin-right: 40px;
  }

  &-content {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    .rounded-button {
      .text-with-count {
        display: flex;
        align-items: center;
        justify-content: center;
        .count {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          font-weight: 400;
          margin-left: 16px;
          background-color: var(--primary-black);
          position: relative;
          p {
            font-family: 'Toyota-Type Regular';
            font-size: 20px;
            color: var(--white);
            position: absolute;
            top: 50%;
            left: 50%;
            height: 100%;
            transform: translate(-50%, -50%);
          }
        }
      }
      &:disabled {
        .text-with-count {
          .count {
            background-color: var(--gray-4);
          }
        }
        border-color: var(--gray-4);
      }
      &:active {
        opacity: 0.8;
      }
    }

    .left-buttons {
      display: flex;
      .rounded-button {
        //flex-wrap: wrap;
        padding: 0 10px;
        svg {
          width: 26px;
          height: 26px;
        }
        .text-with-count {
          //flex-wrap: wrap;
        }
      }
    }

    .right-buttons {
      display: flex;
      margin-left: auto;
    }

    .right &__button {
      border: 3px solid var(--black);
      border-radius: 100px;
      height: 32px;
      width: 112px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.5;
      margin-right: 10px;

      p {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        margin-left: 20px;
      }
    }

    &__hints-button {
      display: flex;
      align-items: center;
    }
  }
  .resetLocal {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 40px;
    opacity: 0;
  }
  &-shutDownBtn {
    display: flex;
    align-items: center;
    border-radius: 96px;
    // background-color: rgb(228, 228, 228);
    color: rgb(40, 40, 48);
    border-width: 0px;
    padding: 10px 15px;
    &__text {
      font-size: 20px;
      margin-left: 10px;
      margin-right: 0;
      margin-bottom: 5px;
      font-family: "Toyota-Type Regular";
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.consultatinHeaderBtn {
  border-radius: 100px;
  margin-right: 17px;
  margin-left: 12px!important;
}
