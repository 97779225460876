.car-comparisons-list {
  display: flex;
  width: 100%;
  height: 379px;
  padding: 20px 0;

  .list-of-sections {
    width: 310px;
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 40px;
    height: 100%;
    border-right: 1px solid var(--gray-4);
    overflow-y: scroll;
    overflow-x: hidden;

    &::after {
      display: block;
      content: '';
      height: 48px;
      width: 100%;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 1px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--white);
      margin-top: 48px;
      margin-bottom: 48px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--primary-red);
    }

    .section-list {
      width: 230px;

      &__item {
        display: block;
        width: fit-content;
        font-size: 16px;
        color: var(--primary-black);
        padding-bottom: 12px;
        margin-bottom: 32px;
        border-bottom: 1px solid transparent;
      }
      &__item_active {
        border-bottom: 1px solid var(--primary-red);
        font-family: 'Toyota-Type Regular';
      }

      &__item:last-child {
        margin-bottom: 0;
      }
    }
  }
  .comparisons {
    flex-grow: 1;
    //padding-left: 65px;
    font-family: 'Toyota-Type Regular';
    display: flex;

    overflow-y: scroll;
    overflow-x: hidden;

    &::after {
      display: block;
      content: '';
      height: 58px;
      width: 100%;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--white);
      margin-top: 24px;
      margin-bottom: 24px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--primary-red);
    }

    &-list {
      width: 100%;
      padding-top: 24px;
      height: 100%;

      &__content {
        width: 100%;
        max-width: 33%;
        min-width: 329px;
        padding: 10px 30px;
      }

      &__item {
        margin-bottom: 32px;
        &-title {
          color: #6C7073;
          margin-right: 8px;
        }

        &-category {
          font-family: "Toyota-Type Regular";
          font-size: 16px;
          color: #15151B;
          margin-bottom: 40px;
          padding-top: 40px;
        }

        &-list {
          font-family: "Toyota-Type Regular";
          margin-left: 24px;
          margin-bottom: 10px;
          // margin-bottom: 40px;
           .gray-text {
             color: #6C7073;
             font-size: 16px;
             display: block;
           }
        }

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 1px;
        }
      }
    }
  }

}
