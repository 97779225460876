.brochure-modal {
  width: 1200px;
  height: 701px;
  position: fixed;
  top: 202px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  font-size: 28px;
  color: var(--primary-black);
  background-color: var(--white);
  padding: 48px;
  p {
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
  }
  .title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 30px;
  }
  .subText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-black);
  }
  .wrapperQrCode {
    width: 140px;
    height: 140px;
    border: 1px solid var(--gray-4);
    padding: 10px;
    margin-top: 40px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .description {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: var(--gray-7);
    max-width: 299px;
    margin-top: 16px;
  }
  &__content {
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    button {
      width: fit-content !important;
      padding: 0 20px;
      margin-top: 48px;
    }
  }
}

.tabs {
  &__label {
    font-size: 32px !important;
    font-weight: 300 !important;
    color: var(--primary-black) !important;
    text-transform: none !important;
    font-family: 'Toyota-Type Regular' !important;
    svg {
      margin-bottom: 18px !important;
    }
  }
  &__label-text {
    margin-bottom: 12px;
    font-size: 16px;
    color: var(--primary-black);
  }
}

.active {
  font-weight: 700;
  color: var(--primary-black) !important;
  background-color: var(--primary-red) !important;
  bottom: -2px !important;
  z-index: 10 !important;
}
.scroller {
  height: fit-content !important;
  border-bottom: 1px solid #e4e4e4;
  overflow-x: visible !important;
  overflow: visible !important;
  z-index: 0 !important;
}
.flexContainer {
  justify-content: space-between;
}
