.wrapperTabColors{
  position: absolute;
  width: 100%;
  bottom:10px;
  left: 0;
  min-height: 130px;
  .image-block{
    margin-left: 40px;
    &.car{
      margin: 0 auto;

    }
    .disc-devider {
      height: 20px;
      margin-left: 3.5px;
      margin-right: 11px;
      border-left: 1px solid gray;
    }
  }
}
.wrapperLoadCarStyleSpinner{
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

}
.colorContentView{
  .wrapperLabel{
    display: flex;
    align-items: center;
    margin-left: 23px;
  }
  .wrapperPriceBlock{
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-left: 16px;
    min-width: fit-content;
    .price{
      font-size: 16px;
      display: inline-block;
      width: auto;
      color: var(--primary-black);
      background-color: var(--gray-4);
      text-align: center;
      padding: 0 8px;
    }
    .icon{
      width: 14px!important;
      height: 14px!important;
      margin-left: 8px;
      svg{
        width: 14px;
        height: 14px;
      }
    }
  }
  .wrapperDiscount{
    display: flex;
    align-items: center;
    margin-left: 8px;
    .icon{
      width: 14px!important;
      height: 14px!important;
      margin-left: 8px;
      svg{
        width: 14px;
        height: 14px;
      }
    }
    .package__discount{
      color: #6C7073;
      font-size: 13px;
      line-height: 20px;
      text-decoration: line-through;
    }
  }
  .viewTabWrapper{
    display: flex;
    position: absolute;
    left: 40px;
    top:47px;
    z-index: 99;
    .viewBtn{
      &:first-child{
        margin-right: 33px;
      }
      svg{
        fill: var(--gray-6);
      }
      &.active{
        svg{
          fill: var(--primary-black);
        }

      }
    }
  }
}

