.inputWrap {
  border: 1px solid var(--gray-4);
  padding: 16px 20px;
  position: relative;
  display: flex;
  z-index: 0;
  height: 56px;
  width: 100%;
  &.error {
    border-color: var(--primary-red);
  }
}
.label {
  font-size: 16px;
  color: var(--gray-7);
  position: absolute;
  transition: all 0.3s;
  top: calc(50% - 25%);
  z-index: 1;
  display: block;
  line-height: normal;
  &.focus {
    top: 7px;
    left: 20px;
    font-size: 10px;
  }
}
.input {
  border: none;
  outline: none;
  font-size: 16px;
  line-height: normal;
  color: var(--gray-7);
  position: absolute;
  left: 20px;
  top: calc(50% - 25%);
  z-index: 1;
  display: block;
  width: calc(100% - 80px);
  background: transparent;
  &.focus {
    top: 38%;
  }
}
.required {
  color: var(--primary-red);
  margin-left: 7px;
}
.errorName {
  position: absolute;
  bottom: -18px;
  left: 0;
  z-index: 10;
  font-size: 13px;
  color: var(--primary-red);
}
.disabled {
  .inputWrap {
    background-color: var(--gray-4);
  }
}
