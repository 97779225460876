.wrapper {
  width: 100%;
  height: 100%;
  display: flex;

  .right {
    display: flex;
    flex-direction: column;
    padding: 48px 48px 48px 0;
    margin-left: 80px;
    width: 100%;
  }

  .main {
    width: 100%;
    height: 100%;
    margin-top: 48px;
    margin-bottom: 48px;
  }
}
.configuratorModelSwiper{
  position: absolute!important;
  width: calc(102% + 38px);
  .swiper-wrapper{
  }
  .swiper-slide{
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 20px);
    left: -20px;
    &.swiper-slide-active{
      padding-left: 20px;
      &:last-child{
        padding-left: 0;
        width: calc(100% - 38px);
      }
      &:first-child{
        padding-left: 20px!important;
      }
    }

  }
}
