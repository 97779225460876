.tooltip-wrapper {
  position: relative;

  .content {
    width: 252px;
    // height: fit-content;
    right: 0;
    top: -50px;
    position: absolute;
    padding: 8px 10px;
    font-size: 16px;
    color: var(--gray-7);
    border: 1px solid var(--gray-4);
    background-color: var(--white);
    // overflow-wrap: break-word;
    z-index: 9;
    &.right {
      width: 165px;
      top: 2px;
      right: -165px;
      padding: 8px 10px;
    }
    &.left {
      top: 2px;
      left: -276px;
      padding: 8px 10px;
    }
    &.top-right {
      width: 165px;
      top: -25px;
      right: -170px;
      padding: 8px 10px;
    }
    &.top-left {
      width: 165px;
      bottom: 7px;
      top: auto;
      left: -175px;
      padding: 8px 10px;
    }
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: ;
  width: 100%;
  height: 100%;
}
