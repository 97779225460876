.selectWrap {
  border: 1px solid var(--gray-4);
  position: relative;
  &.error {
    border-color: var(--primary-red);
  }
}
.required {
  color: var(--primary-red);
  margin-left: 7px;
}
.errorName {
  position: absolute;
  bottom: -18px;
  left: 0;
  z-index: 0;
  font-size: 13px;
  color: var(--primary-red);
}
.disabled {
  .inputWrap {
    background-color: var(--gray-4);
  }
}
