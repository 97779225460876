.footer {
  margin-top: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // justify-content: space-between;

  .pagination {
    display: flex;
    align-items: center;
    margin-right: auto;
    &-pages {
      width: 130px;
      text-align: center;
      font-size: 20px;
      color: var(--gray-6);

      &__current-page {
        color: var(--primary-black);
      }
    }
  }
  .navigation-buttons {
    display: flex;

    &.first{
      margin-right: auto;
      // margin-right: 24px;
      padding-right: 24px;
      button{
        // width:150px!important;
      }
    }
  }
  &-content {
    display: flex;
    margin-right: 435px;
    &.configurator-footer{
      width: 100%;
      justify-content: space-between;
      .navigation-buttons {
        margin-left: 0;
      }
    }

    &__button {
      height: 64px;
      width: 303px;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
      }
    }

    .rate-my-car-button {
      color: var(--primary-black);
      background-color: var(--white);
    }

    .request-consult-button {
      color: var(--white);
      background-color: var(--primary-black);
    }

    .navigation-buttons {
      margin-left: auto;
      display: flex;
      &.first{
        margin-right: 10px;
      }
    }
  }
}

.maintenanceInfo {
  .footer {
    justify-content: flex-start;
  }
}