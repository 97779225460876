.slider-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  overflow: hidden;
  z-index: 10;
  border-radius: 50%;

  .background {
    position: absolute;
    background: var(--primary-black);
    opacity: 0.9;
    width: 100%;
    height: 100%;
  }

  .chevron {
    z-index: 11;

    &__left {
      border-bottom: 4px solid var(--white);
      border-left: 4px solid var(--white);
      transform: rotate(45deg) translate(15%, -20%);
    }

    &__right {
      border-bottom: 4px solid var(--white);
      border-right: 4px solid var(--white);
      transform: rotate(-45deg) translate(-10%, -15%);
    }
  }

  &:active {
    border: 2px solid var(--primary-black);

    .background {
      background: var(--white);
      opacity: 1;
    }

    .chevron {
      border-color: var(--primary-black);
    }
  }

  &.whiteColor{
    border: 1px solid var(--primary-black);
    .background{
      background-color: var(--white);
    }
    .chevron {
      z-index: 11;

      &__left {
        border-bottom: 4px solid var(--primary-black);
        border-left: 4px solid var(--primary-black);
        transform: rotate(45deg) translate(15%, -20%);
      }

      &__right {
        border-bottom: 4px solid var(--primary-black);
        border-right: 4px solid var(--primary-black);
        transform: rotate(-45deg) translate(-10%, -15%);
      }
    }
    &:active {
      border: 2px solid var(--white);

      .background {
        background: var(--primary-black);
        opacity: 1;
      }

      .chevron {
        border-color: var(--white);
      }
    }
  }
}
