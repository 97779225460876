.swiperSlide {
  background-color: white;
  height: 688px;
  width: calc(33.3% - 24px);
  // width: calc(416px - 24px);
  margin-right: 24px;
  .swiper-slide {
    width: 100%!important;
    left: 0!important;
    padding-left: 0!important;
  }
  &__img {
    max-height: 234px;
    margin: 0 auto;
  }

  &-content {
    padding-top: 20px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 468px;
    .mt-5 {
      margin-top: 1rem;
    }

    .description {
      margin-top: 16px;
    }

    &__title {
      font-family: 'Toyota-Type Regular';
      font-size: 28px;
      color: var(--primary-black);
      text-align: center;
      margin-bottom: 5px;
      line-height: 34px;
      padding: 0 10px;
    }

    &__pack {
      font-size: 24px;
      color: var(--gray-7);
      margin-bottom: 5px;
      font-family: 'Toyota-Type Regular';
    }

    &__subtitle {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: var(--gray-7);
    }

    &__price {
      color: var(--primary-black);
      font-size: 16px;
      // background: var(--gray-4);
      padding: 0 8px;
      width: fit-content;
    }

    &__text {
      font-family: 'Toyota-Type Regular';
      font-size: 16px;
      color: var(--primary-black);
    }

    &__about {
      text-align: center;
    }
  }
}
.mySwiperMedium {
  max-height: 468px;
}
