.successful {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  &__text {
    text-align: center;
    line-height: 32px;
    margin-bottom: 48px;
  }
  &__actionBtn {
    .btn {
      font-size: 16px;
      color: var(--white);
      background-color: var(--primary-black);
      padding: 12px 24px;
    }
  }
}
.modal {
  width: 1200px;
  height: 532px;
  position: fixed;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  font-size: 28px;
  color: var(--primary-black);
  background-color: var(--white);
  padding: 72px;
  &__title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 33px;
  }
  &__content {
    max-width: 555px;
    width: 100%;
  }
}
.form {
  &__line {
    margin-bottom: 16px;
    &.borderType_1 {
      border-top: 1px solid var(--gray-4);
      border-bottom: 1px solid var(--gray-4);
      margin-top: 30px;
      margin-bottom: 24px;
      padding-top: 15px;
      padding-top: 25px;
      padding-bottom: 30px;
    }
    &.buttonWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        span {
          font-size: 16px;
        }
      }
    }
  }
  .submitButton {
    font-size: 16px;
    width: 200px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    background-color: var(--primary-black);
  }
}
