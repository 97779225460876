.modal-close-button {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 24px;
  top: 24px;
  background-color: var(--primary-black);
  color: white;
  font-size: 12px;
  border-radius: 50%;
  opacity: 0.9;
  cursor: pointer;
  z-index: 99;
  svg {
    width: 10px;
    height: 10px;
  }

  &:active {
    opacity: 0.8;
  }
}
