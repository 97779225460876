.wrapperTab{
  border-bottom: 1px solid var(--gray-4);
  .wrapperTabItem{
    margin-top: 52px;
    padding: 0 0 20px 0;
    margin-right: 40px;
    &.active{
      border-bottom: 1px solid var(--primary-red);
      margin-bottom: -3px;

      p {
        font-family: "Toyota-Type Regular";
      }
    }
    &:last-child{
      margin-right: 0;
    }
    .wrapperIcon{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 18px;
      svg, img{
        width: 40px;
        height: 40px;
        margin: 0;
      }
    }
    .label{
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

  }
}
