.custom-list-item {
  align-items: center;
  list-style-type: none;
  font-size: 16px;
  color: var(--primary-black);
  margin-bottom: 16px;
  display: flex;
  font-family: 'Toyota-Type Book';

  .circle-wrapper {
    margin-right: 16px;

    .circle {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--primary-red);
    }
  }
}
